exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-learn-mouse-sticky-effect-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/learn/mouse-sticky-effect.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-learn-mouse-sticky-effect-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-dummy-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/dummy.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-dummy-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-1-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-1.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-1-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-10-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-10.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-10-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-11-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-11.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-11-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-12-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-12.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-12-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-13-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-13.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-13-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-14-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-14.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-14-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-15-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-15.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-15-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-16-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-16.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-16-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-17-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-17.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-17-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-18-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-18.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-18-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-19-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-19.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-19-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-2-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-2.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-2-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-20-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-20.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-20-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-21-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-21.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-21-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-22-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-22.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-22-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-24-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-24.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-24-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-25-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-25.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-25-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-26-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-26.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-26-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-3-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-3.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-3-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-4-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-4.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-4-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-5-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-5.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-5-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-6-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-6.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-6-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-7-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-7.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-7-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-8-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-8.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-8-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-9-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-9.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-9-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-learn-mouse-sticky-effect-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/learn/mouse-sticky-effect.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-learn-mouse-sticky-effect-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-dummy-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/dummy.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-dummy-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-1-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-1.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-1-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-10-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-10.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-10-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-11-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-11.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-11-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-12-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-12.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-12-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-13-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-13.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-13-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-14-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-14.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-14-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-15-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-15.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-15-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-16-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-16.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-16-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-17-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-17.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-17-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-18-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-18.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-18-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-19-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-19.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-19-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-2-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-2.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-2-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-20-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-20.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-20-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-21-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-21.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-21-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-22-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-22.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-22-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-24-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-24.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-24-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-25-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-25.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-25-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-26-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-26.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-26-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-3-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-3.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-3-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-4-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-4.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-4-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-5-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-5.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-5-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-6-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-6.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-6-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-7-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-7.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-7-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-8-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-8.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-8-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-9-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/template-post-9.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-template-post-9-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-index-tsx": () => import("./../../../src/pages/info/index.tsx" /* webpackChunkName: "component---src-pages-info-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-templates-learn-page-template-tsx": () => import("./../../../src/templates/LearnPageTemplate.tsx" /* webpackChunkName: "component---src-templates-learn-page-template-tsx" */),
  "component---src-templates-life-page-template-tsx": () => import("./../../../src/templates/LifePageTemplate.tsx" /* webpackChunkName: "component---src-templates-life-page-template-tsx" */)
}

